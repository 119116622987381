// Payroll.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config';
import { Box, Button, Select, MenuItem, Typography, Card, CardContent, Grid, Divider, Alert, TextField, Paper } from '@mui/material';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';

function Payroll() {
    const [employees, setEmployees] = useState([]);
    const [selectedEmployeeId, setSelectedEmployeeId] = useState('');
    const [employeeCost, setEmployeeCost] = useState(null);
    const [error, setError] = useState(null);
    const [billingRate, setBillingRate] = useState('');

    useEffect(() => {
        const loadEmployees = async () => {
            try {
                const response = await axios.get(`${config.baseUrl}/api/csv/employees`);
                setEmployees(Array.isArray(response.data) ? response.data : []);
            } catch (error) {
                setError('Error loading employees. Please try again.');
                setEmployees([]);
            }
        };
        loadEmployees();
    }, []);

    const handleFetchCost = async () => {
        if (!selectedEmployeeId) {
            setError('Please select an Employee');
            return;
        }
        try {
            const response = await axios.get(`${config.baseUrl}/api/csv/employee/${selectedEmployeeId}/cost`);
            setEmployeeCost(response.data);
            setError(null);
        } catch (error) {
            setError('Error fetching employee cost. Please try again.');
        }
    };

    const calculateEmployerCostBreakdown = () => {
        const salaryCost = parseFloat(employeeCost['Gross Earnings'] || 0).toFixed(2);

        const additionalCosts = [
            'Sick Amount',
            '401(k) Company Deduction',
            'Social Security (Employer)',
            'Medicare (Employer)',
            'FUTA (Employer)',
            'CA SUI (Employer)',
            'CA ETT (Employer)',
            'Mileage',
            'Admin Adjusted Rate'
        ];

        const breakdown = additionalCosts.map((key) => {
            const value = parseFloat(employeeCost[key] || 0).toFixed(2);
            return { name: key, value: parseFloat(value) };
        });

        const totalAdditionalCosts = breakdown.reduce((sum, { value }) => sum + value, 0).toFixed(2);
        const totalEmployerCost = (parseFloat(salaryCost) + parseFloat(totalAdditionalCosts)).toFixed(2);

        return { salaryCost, breakdown, totalAdditionalCosts, totalEmployerCost };
    };

    const calculateRevenue = () => {
        const regularHours = parseFloat(employeeCost['Regular Hours'] || 0);
        const rate = parseFloat(billingRate || 0);
        return (regularHours * rate).toFixed(2);
    };

    const calculateProfitPercentage = () => {
        const revenue = parseFloat(calculateRevenue());
        const totalEmployerCost = parseFloat(calculateEmployerCostBreakdown().totalEmployerCost);
        if (revenue === 0 || totalEmployerCost === 0) return "0.00%";
        const percentage = ((revenue - totalEmployerCost) / revenue) * 100;
        return `${percentage.toFixed(2)}%`;
    };

    const renderSection = (title, fields) => (
        <Box sx={{ mb: 4 }}>
            <Typography variant="h6" gutterBottom>{title}</Typography>
            <Grid container spacing={2}>
                {fields.map(([key, value]) => (
                    <Grid item xs={6} key={key} sx={{ mb: 1 }}>
                        <strong>{key}:</strong> {value}
                    </Grid>
                ))}
            </Grid>
            <Divider sx={{ my: 2 }} />
        </Box>
    );

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#845EC2', '#D65DB1', '#FF6F91', '#FFC75F'];

    const renderCustomizedLabel = ({ name, value, percent }) => {
        return `${name}: $${value} (${(percent * 100).toFixed(2)}%)`;
    };

    return (
        <Box sx={{ padding: 4 }}>
            <Typography variant="h4" gutterBottom>Employee Cost Viewer</Typography>

            {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}

            <Box sx={{ mb: 4, display: 'flex', gap: 2, alignItems: 'center' }}>
                <Select
                    value={selectedEmployeeId}
                    onChange={(e) => setSelectedEmployeeId(e.target.value)}
                    displayEmpty
                    fullWidth
                >
                    <MenuItem value="" disabled>Select Employee</MenuItem>
                    {employees.map((employee) => (
                        <MenuItem key={employee.id} value={employee.id}>
                            {employee.firstName} {employee.lastName}
                        </MenuItem>
                    ))}
                </Select>
                <Button variant="contained" color="primary" onClick={handleFetchCost}>View Cost</Button>
            </Box>

            {employeeCost && (
                <Card variant="outlined">
                    <CardContent>
                        <Typography variant="h5" gutterBottom>Employee Cost Details</Typography>

                        {renderSection("Employee Information", [
                            ["Employee Name", employeeCost["Employee"] || "N/A"],
                            ["Work Address", employeeCost["Work Address"] || "N/A"]
                        ])}

                        {renderSection("Regular Hours Details", [
                            ["Regular Hours Worked", employeeCost['Regular Hours'] || 0],
                            ["Regular Amount", employeeCost['Regular Amount'] || 0],
                            ["Sick Amount", employeeCost['Sick Amount'] || 0],
                            ["Gross Earnings (Total)", `${employeeCost['Regular Amount'] || 0} + ${employeeCost['Sick Amount'] || 0} = ${employeeCost['Gross Earnings'] || 0}`]
                        ])}

                        <Typography variant="h6" gutterBottom>Employer Cost Breakdown</Typography>

                        <ResponsiveContainer width="100%" height={400}>
                            <PieChart>
                                <Pie
                                    data={calculateEmployerCostBreakdown().breakdown}
                                    dataKey="value"
                                    nameKey="name"
                                    cx="50%"
                                    cy="50%"
                                    outerRadius={150}
                                    fill="#8884d8"
                                    label={renderCustomizedLabel}
                                >
                                    {calculateEmployerCostBreakdown().breakdown.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                                <Tooltip />
                                <Legend />
                            </PieChart>
                        </ResponsiveContainer>

                        <Typography sx={{ mt: 2 }}>
                            <strong>Total Employer Cost:</strong> {calculateEmployerCostBreakdown().totalEmployerCost}
                        </Typography>

                        <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
                            <Typography variant="h5" gutterBottom>Revenue Generated</Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label="Billing Rate"
                                        type="number"
                                        value={billingRate}
                                        onChange={(e) => setBillingRate(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h6" gutterBottom>
                                        <strong>Revenue Generated:</strong> ${calculateRevenue()}
                                    </Typography>
                                    <Typography variant="h6" gutterBottom sx={{ color: 'green', mt: 1 }}>
                                        <strong>Profit Percentage:</strong> {calculateProfitPercentage()}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </CardContent>
                </Card>
            )}
        </Box>
    );
}

export default Payroll;
