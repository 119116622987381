import React, { useEffect, useState } from 'react';
import {
    Box,
    CircularProgress,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    Divider,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Button,
    IconButton
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';
import axios from 'axios';
import config from '../config';

const BillingInfo = () => {
    const [sessionData, setSessionData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedStudent, setSelectedStudent] = useState('All');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [editingRow, setEditingRow] = useState(null);
    const [editedLocation, setEditedLocation] = useState('');

    useEffect(() => {
        axios
            .get(`${config.baseUrl}/api/excel/sessions`)
            .then((response) => {
                setSessionData(response.data);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                alert('Failed to fetch session data.');
            })
            .finally(() => setLoading(false));
    }, []);

    const handleEdit = (rowId, currentLocation) => {
        setEditingRow(rowId);
        setEditedLocation(currentLocation);
    };

    const handleSave = (rowId) => {
        axios
            .put(`${config.baseUrl}/api/excel/location/${rowId}`, { location: editedLocation })
            .then(() => {
                setSessionData((prevData) =>
                    prevData.map((session) =>
                        session.id === rowId ? { ...session, location: editedLocation } : session
                    )
                );
                setEditingRow(null);
            })
            .catch((error) => {
                console.error('Error updating location:', error);
                alert('Failed to update location.');
            });
    };

    const filteredSessions = sessionData.filter((session) => {
        const sessionDate = new Date(session.start_date).getTime();
        const withinDateRange =
            (!startDate || sessionDate >= new Date(startDate).setHours(0, 0, 0, 0)) &&
            (!endDate || sessionDate <= new Date(endDate).setHours(23, 59, 59, 999));
        const matchesStudent = selectedStudent === 'All' || session.student_name === selectedStudent;
        return withinDateRange && matchesStudent;
    });

    return (
        <Box style={{ paddingBottom: '100px' }}>
            <Paper
                elevation={3}
                style={{
                    padding: '20px',
                    borderRadius: '10px',
                    backgroundColor: '#f0f8ff', // Light blue background for the entire card
                }}
            >
                <Typography
                    variant="h4"
                    style={{ fontWeight: 'bold', textAlign: 'center', marginBottom: '20px', color: '#003366' }}
                >
                    Billing Information
                </Typography>
                <Divider style={{ marginBottom: '20px' }} />

                {/* Filters Section */}
                <Box
                    display="flex"
                    flexWrap="wrap"
                    gap="20px"
                    alignItems="center"
                    marginBottom="20px"
                    style={{
                        padding: '15px',
                        backgroundColor: '#e6f7ff', // Light blue filter area
                        borderRadius: '10px',
                    }}
                >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="Start Date"
                            value={startDate}
                            onChange={(date) => setStartDate(date)}
                            renderInput={(params) => <FormControl style={{ minWidth: '150px' }} {...params} />}
                        />
                        <DatePicker
                            label="End Date"
                            value={endDate}
                            onChange={(date) => setEndDate(date)}
                            renderInput={(params) => <FormControl style={{ minWidth: '150px' }} {...params} />}
                        />
                    </LocalizationProvider>

                    <FormControl style={{ minWidth: '200px' }}>
                        <InputLabel>Select Student</InputLabel>
                        <Select
                            value={selectedStudent}
                            onChange={(e) => setSelectedStudent(e.target.value)}
                        >
                            <MenuItem value="All">All</MenuItem>
                            {[...new Set(sessionData.map((session) => session.student_name))].map((name) => (
                                <MenuItem key={name} value={name}>
                                    {name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                {/* Data Table */}
                {loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
                        <CircularProgress />
                    </Box>
                ) : filteredSessions.length > 0 ? (
                    <Table
                        style={{
                            borderRadius: '10px',
                            boxShadow: '0 2px 10px rgba(0,0,0,0.1)', // Subtle shadow for table
                        }}
                    >
                        <TableHead
                            style={{
                                backgroundColor: '#003366', // Navy blue header
                                color: 'white',
                            }}
                        >
                            <TableRow>
                                <TableCell style={{ color: 'white', fontWeight: 'bold' }}>Therapist</TableCell>
                                <TableCell style={{ color: 'white', fontWeight: 'bold' }}>Start Date & Time</TableCell>
                                <TableCell style={{ color: 'white', fontWeight: 'bold' }}>End Date & Time</TableCell>
                                <TableCell style={{ color: 'white', fontWeight: 'bold' }}>Location</TableCell>
                                <TableCell style={{ color: 'white', fontWeight: 'bold' }}>Duration (HH:mm)</TableCell>
                                <TableCell style={{ color: 'white', fontWeight: 'bold' }}>Notes</TableCell>
                                <TableCell style={{ color: 'white', fontWeight: 'bold' }}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredSessions.map((session, index) => (
                                <TableRow
                                    key={session.id}
                                    style={{
                                        backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#ffffff', // Alternating row colors
                                    }}
                                >
                                    <TableCell>{session.therapist}</TableCell>
                                    <TableCell>{new Date(session.start_date).toLocaleString()}</TableCell>
                                    <TableCell>{new Date(session.end_date).toLocaleString()}</TableCell>
                                    <TableCell>
                                        {editingRow === session.id ? (
                                            <FormControl>
                                                <Select
                                                    value={editedLocation}
                                                    onChange={(e) => setEditedLocation(e.target.value)}
                                                    style={{ minWidth: '120px' }}
                                                >
                                                    <MenuItem value="HOME">HOME</MenuItem>
                                                    <MenuItem value="REMOTE">REMOTE</MenuItem>
                                                </Select>
                                            </FormControl>
                                        ) : (
                                            <span
                                                style={{
                                                    color:
                                                        session.location === 'HOME' ? '#2e8b57' : '#1e90ff', // Green for HOME, Blue for REMOTE
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                {session.location}
                                            </span>
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {`${Math.floor(session.total_session_duration / 60)}h ${
                                            session.total_session_duration % 60
                                        }m`}
                                    </TableCell>
                                    <TableCell>{session.text || 'N/A'}</TableCell>
                                    <TableCell>
                                        {editingRow === session.id ? (
                                            <IconButton onClick={() => handleSave(session.id)}>
                                                <CheckIcon style={{ color: 'green' }} />
                                            </IconButton>
                                        ) : (
                                            <IconButton onClick={() => handleEdit(session.id, session.location)}>
                                                <EditIcon style={{ color: '#1e90ff' }} />
                                            </IconButton>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                ) : (
                    <Typography style={{ textAlign: 'center', color: '#757575' }}>
                        No session data available for the selected filters.
                    </Typography>
                )}
            </Paper>
        </Box>
    );
};

export default BillingInfo;
