import React from 'react';
import { AppBar, Toolbar, Typography, Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';

function Header({ totalDeposits = 0, totalExpenses = 0 }) {
    // Function to handle user log-out
    const handleLogout = () => {
        // Remove authentication data from local storage or session storage
        localStorage.removeItem('authToken'); // Replace 'authToken' with your actual key if needed
        // Redirect to the login or home page
        window.location.href = '/login'; // Adjust path as needed
    };

    return (
        <AppBar position="static" style={{ backgroundColor: '#4a4a4a', padding: '10px 20px' }}>
            {/* Muted gray color */}
            <Toolbar>
                <div
                    style={{
                        backgroundColor: 'white',
                        padding: '5px',
                        borderRadius: '8px',
                        display: 'inline-block',
                    }}
                >
                    <img src="/logo.png" alt="Logo" style={{ width: '50px' }} />
                </div>
                <Typography
                    variant="h6"
                    style={{ flexGrow: 1, color: 'white', marginLeft: '15px' }}
                >
                    Financial Dashboard
                </Typography>
                <Box display="flex" alignItems="center">
                    <Link
                        to="/"
                        style={{
                            color: 'white',
                            textDecoration: 'none',
                            marginRight: '15px',
                        }}
                    >
                        Home
                    </Link>
                    <Link
                        to="/profit-loss"
                        style={{
                            color: 'white',
                            textDecoration: 'none',
                            marginRight: '15px',
                        }}
                    >
                        Profit & Loss Chart
                    </Link>
                    <Link
                        to="/billing"
                        style={{
                            color: 'white',
                            textDecoration: 'none',
                            marginRight: '15px',
                        }}
                    >
                        Billing
                    </Link>
                    <Link to="/payroll" style={{ color: 'white', textDecoration: 'none', marginRight: '15px' }}>
                        Payroll
                    </Link> {/* Added Payroll link */}
                    <Button
                        color="inherit"
                        onClick={handleLogout}
                        style={{
                            textDecoration: 'none',
                        }}
                    >
                        Log Out
                    </Button>
                </Box>
            </Toolbar>
        </AppBar>
    );
}

export default Header;
