// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { auth } from './components/firebaseConfig'; // Ensure this file exists and is imported correctly
import { onAuthStateChanged } from 'firebase/auth';
import Login from './components/Login';
import axios from 'axios';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Typography, Box, Grid, Paper, Button, CircularProgress } from '@mui/material';
import DatePickers from './components/DatePickers';
import TransactionGroup from './components/TransactionGroup';
import SummarySection from './components/SummarySection';
import ProfitLossChart from './components/ProfitLossChart';
import Header from './components/Header'; // Existing component
import config from './config';
import BillingInfo from './components/BillingInfo'; // Import BillingInfo component
import Payroll from './components/Payroll'; // Added Payroll import


function App() {
    const [user, setUser] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [transactions, setTransactions] = useState([]);
    const [hiddenTransactions, setHiddenTransactions] = useState(new Set());
    const [expandedGroup, setExpandedGroup] = useState(null);
    const [loading, setLoading] = useState(false); // New loading state

    // Listen for authentication state changes
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
        });
        return () => unsubscribe();
    }, []);

    const handleSaveVendorType = (vendor, type) => {
        axios.put(`${config.baseUrl}/api/csv/${vendor}`, { type })
            .then(() => alert("Vendor classification saved successfully"))
            .catch(error => {
                console.error("Error saving vendor type:", error);
                alert("Failed to save vendor classification. Please try again.");
            });
    };

    const handleSubmit = () => {
        if (!startDate || !endDate) {
            alert("Please select both start and end dates");
            return;
        }

        setLoading(true); // Start spinner

        const formattedStartDate = startDate.toISOString().split('T')[0];
        const formattedEndDate = endDate.toISOString().split('T')[0];

        axios.get(`${config.baseUrl}/api/csv/expenses`, {
            params: {
                startDate: formattedStartDate,
                endDate: formattedEndDate,
            }
        })
        .then(response => {
            setTransactions(response.data);
            setHiddenTransactions(new Set());
        })
        .catch(error => {
            console.error("Error fetching transactions:", error);
            alert("Failed to fetch transactions. Please try again.");
        })
        .finally(() => {
            setLoading(false); // Stop spinner
        });
    };

    const groupTransactionsByVendor = (transactions) => {
        return transactions.reduce((acc, transaction) => {
            const vendor = transaction.tag?.tag || "Unknown Vendor";
            acc[vendor] = acc[vendor] || [];
            acc[vendor].push(transaction);
            return acc;
        }, {});
    };

    const calculateTotalAmount = (group) => {
        return group
            .filter(transaction => !hiddenTransactions.has(transaction.id))
            .reduce((sum, transaction) => sum + Math.abs(transaction.amount), 0)
            .toFixed(2);
    };

    const handleCheckboxChange = (transactionId) => {
        setHiddenTransactions(prev => {
            const newHiddenTransactions = new Set(prev);
            if (newHiddenTransactions.has(transactionId)) {
                newHiddenTransactions.delete(transactionId);
            } else {
                newHiddenTransactions.add(transactionId);
            }
            return newHiddenTransactions;
        });
    };

    const handleHideAllChange = (vendorTransactions, checked) => {
        setHiddenTransactions(prev => {
            const newHiddenTransactions = new Set(prev);
            vendorTransactions.forEach(transaction => {
                if (checked) {
                    newHiddenTransactions.add(transaction.id);
                } else {
                    newHiddenTransactions.delete(transaction.id);
                }
            });
            return newHiddenTransactions;
        });
    };

    const handleAccordionChange = (vendor) => {
        setExpandedGroup(prev => (prev === vendor ? null : vendor));
    };

    const deposits = groupTransactionsByVendor(transactions.filter(transaction => transaction.type.toLowerCase() === 'deposit'));
    const expenses = groupTransactionsByVendor(transactions.filter(transaction => transaction.type.toLowerCase() === 'expense'));

    const totalDeposits = parseFloat(calculateTotalAmount(Object.values(deposits).flat()));
    const totalExpenses = parseFloat(calculateTotalAmount(Object.values(expenses).flat()));
    const profitOrLoss = (totalDeposits - totalExpenses).toFixed(2);
    const isProfit = profitOrLoss >= 0;

    return (
        <Router>
            <Header />
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route
                    path="/"
                    element={
                        user ? (
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Paper elevation={3} style={{ margin: '20px', padding: '20px' }}>
                                    {loading ? ( // Show spinner if loading
                                        <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
                                            <CircularProgress />
                                        </Box>
                                    ) : (
                                        <>
                                            <Grid container spacing={3} alignItems="center">
                                                <Grid item xs={12} md={6}>
                                                    <DatePickers
                                                        startDate={startDate}
                                                        setStartDate={setStartDate}
                                                        endDate={endDate}
                                                        setEndDate={setEndDate}
                                                        onSubmit={handleSubmit}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <SummarySection
                                                        totalDeposits={totalDeposits}
                                                        totalExpenses={totalExpenses}
                                                        profitOrLoss={profitOrLoss}
                                                        isProfit={isProfit}
                                                        startDate={startDate}
                                                    />
                                                </Grid>
                                            </Grid>
                                            {transactions.length > 0 && (
                                                <>
                                                    <Typography variant="h5" style={{ margin: '20px 0' }}>Deposits</Typography>
                                                    {Object.entries(deposits).map(([vendor, vendorTransactions]) => (
                                                        <TransactionGroup
                                                            key={vendor}
                                                            transactions={vendorTransactions}
                                                            hiddenTransactions={hiddenTransactions}
                                                            onHideAllChange={handleHideAllChange}
                                                            onCheckboxChange={handleCheckboxChange}
                                                            vendor={vendor}
                                                            expanded={expandedGroup === vendor}
                                                            onAccordionChange={() => handleAccordionChange(vendor)}
                                                        />
                                                    ))}
                                                    <Typography variant="h5" style={{ margin: '20px 0' }}>Expenses</Typography>
                                                    {Object.entries(expenses).map(([vendor, vendorTransactions]) => (
                                                        <TransactionGroup
                                                            key={vendor}
                                                            transactions={vendorTransactions}
                                                            hiddenTransactions={hiddenTransactions}
                                                            onHideAllChange={handleHideAllChange}
                                                            onCheckboxChange={handleCheckboxChange}
                                                            vendor={vendor}
                                                            expanded={expandedGroup === vendor}
                                                            onAccordionChange={() => handleAccordionChange(vendor)}
                                                            onSaveVendorType={handleSaveVendorType}
                                                        />
                                                    ))}
                                                </>
                                            )}
                                        </>
                                    )}
                                </Paper>
                            </LocalizationProvider>
                        ) : (
                            <Navigate to="/login" />
                        )
                    }
                />
                <Route path="/profit-loss" element={user ? <ProfitLossChart/> : <Navigate to="/login"/>} />
               <Route path="/billing" element={user ? <BillingInfo /> : <Navigate to="/login" />} />
               <Route path="/payroll" element={user ? <Payroll /> : <Navigate to="/login" />} /> {/* Added route */}
            </Routes>
        </Router>
    );
}

export default App;
