// TransactionGroup.js
import React from 'react';
import { Button } from '@mui/material';
import {
    Accordion, AccordionSummary, AccordionDetails,
    Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Paper, Typography, Checkbox, Box
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import config from '../config';
import { Select, MenuItem } from '@mui/material';
import { useState } from 'react';

function TransactionGroup({
    transactions, hiddenTransactions, onHideAllChange, onCheckboxChange, vendor, expanded, onAccordionChange, onSaveVendorType
}) {
    const [vendorType, setVendorType] = useState(transactions[0]?.tag?.type || "Select");

    // Calculate total amount
    const visibleTransactions = transactions.filter(transaction => !hiddenTransactions.has(transaction.id));
    const totalAmount = visibleTransactions.reduce((sum, transaction) => sum + Math.abs(transaction.amount), 0).toFixed(2);

    const allHidden = transactions.every(transaction => hiddenTransactions.has(transaction.id));

    const handleHideAllClick = (e) => {
        e.stopPropagation();
        onHideAllChange(transactions, e.target.checked);
    };

    const handleTypeChange = (e) => {
        setVendorType(e.target.value); // Update local state
    };

    const handleSave = () => {
        if (vendorType !== "Select") {
            onSaveVendorType(vendor, vendorType); // Call backend
        } else {
            alert("Please select a type before saving.");
        }
    };

    return (
        <Accordion expanded={expanded} onChange={onAccordionChange}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box display="flex" alignItems="center" width="100%">
                    <Checkbox
                        onChange={handleHideAllClick}
                        checked={allHidden}
                        style={{ marginRight: '10px' }}
                        onClick={(e) => e.stopPropagation()} 
                    />
                    <Typography variant="subtitle1" flexGrow={1}>
                        {vendor} (Total: ${totalAmount})
                    </Typography>
                    <Select
                        value={vendorType}
                        onChange={handleTypeChange}
                        style={{ marginRight: '10px', minWidth: '120px' }}
                    >
                        <MenuItem value="Select" disabled>Select</MenuItem>
                        <MenuItem value="Personal">Personal</MenuItem>
                        <MenuItem value="Business">Business</MenuItem>
                    </Select>
                    <Button variant="contained" color="primary" onClick={handleSave}>
                        Save
                    </Button>
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell><strong>Hide</strong></TableCell>
                                <TableCell><strong>ID</strong></TableCell>
                                <TableCell><strong>Description</strong></TableCell>
                                <TableCell><strong>Amount</strong></TableCell>
                                <TableCell><strong>Date</strong></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {transactions.map(transaction => (
                                <TableRow key={transaction.id}>
                                    <TableCell>
                                        <Checkbox
                                            checked={hiddenTransactions.has(transaction.id)}
                                            onChange={() => onCheckboxChange(transaction.id)}
                                        />
                                    </TableCell>
                                    <TableCell>{transaction.id}</TableCell>
                                    <TableCell>{transaction.description}</TableCell>
                                    <TableCell>{Math.abs(transaction.amount).toFixed(2)}</TableCell>
                                    <TableCell>{transaction.date}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </AccordionDetails>
        </Accordion>
    );
}

export default TransactionGroup;